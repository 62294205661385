import { client, withDataRequest } from 'utils/client';
import { DataResponse, GetSelectList, SelectItem, GetSelectListResponse } from 'api/types';
import { TagFilters, GetMyProgramsTable, ProgramFormData, MyProgramTableView } from 'features/programs/types';
import { GetProgramConnectionsTable, EmployerDiscoverProgramTableView } from 'features/explore-programs/types';
import { GetJoinSelectList } from 'features/network-join';
import { TableDataResponse } from 'features/types';
import { DiscoverProgramTableView, GetDiscoverProgramsTable } from 'features/discover-programs';
import { ProgramsFiltersData } from 'features/programs-filters';

const programsAPI = {
  getProgramTable(params: GetMyProgramsTable) {
    return client.post<TableDataResponse<MyProgramTableView>>('/programs/get-table', withDataRequest(params!));
  },
  addProgram(data: FormData) {
    return client.post<void>('/programs/add', withDataRequest(data));
  },
  getFormData() {
    return client.get<ProgramFormData>('/programs/form-data');
  },
  getEditFormData(id: string) {
    return client.get<ProgramFormData>(`/programs/${id}/form-data`);
  },
  editFormData(id: string, data: FormData) {
    return client.put<void>(`/programs/${id}/edit`, withDataRequest(data));
  },
  getSelectList(params: GetSelectList) {
    return client.post<DataResponse<SelectItem>>(`/programs/get-select-list`, withDataRequest(params));
  },
  getEmployerSelectList(params: GetSelectList) {
    return client.post<GetSelectListResponse>(`/programs/get-employer-select-list`, withDataRequest(params));
  },
  getTags(params: TagFilters) {
    return client.post<DataResponse<SelectItem>>('/tags/get-select-list', withDataRequest(params));
  },
  getEmployerTable(params: GetProgramConnectionsTable) {
    return client.post<TableDataResponse<EmployerDiscoverProgramTableView>>(`/programs/get-employer-table`, withDataRequest(params));
  },
  getJobSeekerTable(params: GetProgramConnectionsTable) {
    return client.post<TableDataResponse<EmployerDiscoverProgramTableView>>(`/programs/get-job-seeker-table`, withDataRequest(params));
  },
  getEmployerFilters() {
    return client.get<ProgramsFiltersData>(`/programs/employer-filters`);
  },
  getJoinSelectList(params: GetJoinSelectList) {
    return client.post<GetSelectListResponse>(`/programs/get-join-select-list`, withDataRequest(params));
  },
  getSpecificJobsSelectList(params: GetSelectList) {
    return client.post<DataResponse<SelectItem>>('/programs/get-specific-jobs-select-list', withDataRequest(params));
  },
  getProgramSpecificJobsSelectList(params: GetSelectList) {
    return client.post<DataResponse<SelectItem>>('/programs/get-program-specific-jobs-select-list', withDataRequest(params));
  },
  getDiscoverTable(params: GetDiscoverProgramsTable) {
    return client.post<TableDataResponse<DiscoverProgramTableView>>(`/programs/get-discover-table`, withDataRequest(params));
  },
  getJobSeekerSelectList(params: GetSelectList) {
    return client.post<GetSelectListResponse>(`/programs/get-jobseeker-select-list`, withDataRequest(params));
  },
};

export default programsAPI;