import { RootState } from 'app/root-reducer';
import { AxiosError } from 'axios';

type Status = 'idle' | 'pending' | 'fulfilled' | 'rejected';

export type DefaultState = {
  status: Status;
  error: string | null;
};

type ThunkAPI = {
  state: RootState;
};

export type RejectWithMessage = ThunkAPI & {
  rejectValue: string;
};

export type RejectWithError = ThunkAPI & {
  rejectValue: AxiosError | Error;
};

export enum Roles {
  Owner = 'Owner',
  User = 'User',
  // ProgramManager = 'ProgramManager',
  // JobSeekerManager = 'JobSeekerManager',
}

export enum CompanyStatuses {
  EmployerDisabled = 'EmployerDisabled',
  OrganizationDisabled = 'OrganizationDisabled',
  EmployerCompleted = 'EmployerCompleted',
  OrganizationCompleted = 'OrganizationCompleted',
  OrganizationInCompleted = 'OrganizationInCompleted',
  EmployerInCompleted = 'EmployerInCompleted',
}

export type DefaultModalProps = {
  isOpen: boolean;
  onSuccess: () => void;
  toggleModal: () => void;
};

export type TableDataResponse<T> = {
  rows: T[];
  totalCount: number;
  noResult: boolean;
};

export type ProgramConnectionListView = {
  programName: string;
  organizationName: string;
}

export enum UserTypes {
  User = 'User',
  JobSeeker = 'JobSeeker',
}

export enum SignInSections {
  UserSingIn = 'UserSingIn',
  JobSeekerSignIn = 'JobSeekerSignIn',
}

export enum FileType {
  Image = 'Image',
  File = 'File',
}

export type ChangeToken = {
  token: string;
};

export enum BackTypes {
  SignIn = 'SignIn',
  SignUp = 'SignUp',
}

export enum EmployerPlanLevels {
  PlanBasic = 'BasicPlan',
  PlanEnhanced = 'EnhancedPlan',
}
