import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getErrorMessage } from 'utils/error';
import { programsAPI, organizationAPI } from 'api';
import { ExploreJobSeekerProgramsState, JobSeekerDiscoverProgramTableView } from './types';
import { Paging } from 'api/types';
import { RootState } from 'app/root-reducer';
import { RejectWithMessage, TableDataResponse } from 'features/types';
import { OrganizationMainFields } from 'features/organizations/types';

export const getJobSeekerPrograms = createAsyncThunk<TableDataResponse<JobSeekerDiscoverProgramTableView>, void, RejectWithMessage>(
  'job-seeker-explore-programs/get-programs',
  async (_, { rejectWithValue, getState }) => {
    try {
      const state: RootState = getState();
      const requestData = { ...state.jobSeekerExplorePrograms.requestData, filters: state.programsFilters.filters };
      return await programsAPI.getJobSeekerTable(requestData);
    } catch (error) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const getJobSeekerCBODetails = createAsyncThunk<OrganizationMainFields, string, RejectWithMessage>(
  'job-seeker-explore-programs/get-cbo-details',
  async (id, { rejectWithValue }) => {
    try {
      return await organizationAPI.getJobSeekerCBODetails(id);
    } catch (error) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

const initialState: ExploreJobSeekerProgramsState = {
  status: 'idle',
  requestData: {
    paging: {
      page: 1,
      pageSize: 20,
    },
  },
  data: {
    tableDataIsLoading: false,
    tableData: {
      rows: [],
      totalCount: 0,
      noResult: false,
    },
    selectedOrganization: null,
  },
  error: null,
};

const jobSeekerExplorePrograms = createSlice({
  name: 'jobSeekerExplorePrograms',
  initialState,
  reducers: {
    setPage: (state, action: PayloadAction<Paging>) => {
      state.requestData.paging.page = action.payload.page;
      state.requestData.paging.pageSize = action.payload.pageSize;
    },
    resetRequestData: (state) => {
      state.requestData = initialState.requestData;
    },
    resetPaging: (state) => {
      state.requestData.paging = initialState.requestData.paging;
    },
    resetState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getJobSeekerPrograms.pending, (state) => {
      state.data.tableDataIsLoading = true;
    });
    builder.addCase(getJobSeekerPrograms.fulfilled, (state, action: PayloadAction<TableDataResponse<JobSeekerDiscoverProgramTableView>>) => {
      state.data.tableDataIsLoading = false;
      state.data.tableData = action.payload;
      state.data.tableData.noResult = state.data.tableData.rows.length === 0;
    });
    builder.addCase(getJobSeekerPrograms.rejected, (state) => {
      state.data.tableDataIsLoading = false;
    });

    builder.addCase(getJobSeekerCBODetails.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(getJobSeekerCBODetails.fulfilled, (state, action: PayloadAction<OrganizationMainFields>) => {
      state.status = 'fulfilled';
      state.data.selectedOrganization = action.payload;
      state.error = null;
    });
    builder.addCase(getJobSeekerCBODetails.rejected, (state) => {
      state.status = 'rejected';
    });
  }
});

export const { setPage, resetRequestData, resetPaging, resetState } = jobSeekerExplorePrograms.actions;
export const jobSeekerExploreProgramsReducer = jobSeekerExplorePrograms.reducer;
