import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { authAPI, jobSeekersAPI } from 'api';
import { AppThunk } from 'app/store';
import { RootState } from 'app/root-reducer';
import { RejectWithMessage } from 'features/types';
import { tokenStorage } from 'services';
import { JobSeekerAuthState, JobSeeker } from './types';
import { getErrorMessage } from 'utils/error';
import { RefreshTokenResponse } from 'features/auth/types';

export const renewAccessToken = createAsyncThunk<RefreshTokenResponse, void, RejectWithMessage>(
  'job-seeker-auth/renew-access-token',
  async (_, { rejectWithValue }) => {
    try {
      const refreshToken = tokenStorage.getRefreshToken();

      if (!refreshToken) {
        throw Error('Session token does not exist');
      }
      const data = await authAPI.renewAccessToken(refreshToken);
      const { accessToken } = data;
      tokenStorage.setAccessToken(accessToken);
      return data;
    } catch (error) {
      return rejectWithValue(getErrorMessage(error));
    }
  },
);

export const getCurrentJobSeeker = createAsyncThunk<JobSeeker, void, RejectWithMessage>(
  'job-seeker-auth/current',
  async (_, { rejectWithValue }) => {
    try {
      return await jobSeekersAPI.current();
    } catch (error) {
      return rejectWithValue(getErrorMessage(error));
    }
  },
);

const initialState: JobSeekerAuthState = {
  isAuthenticated: false,
  jobSeeker: null,
  status: 'idle',
  error: null,
  from: null,
  applyJobId: undefined,
};

const jobSeekerAuthSlice = createSlice({
  name: 'jobSeekerAuth',
  initialState,
  reducers: {
    jobSeekerSignOut: (state, action: PayloadAction<string>) => {
      state.isAuthenticated = false;
      state.jobSeeker = null;
      state.status = 'idle';
      state.error = null;
    },
    rememberJobSeekerLocation: (state, action: PayloadAction<string>) => {
      state.from = action.payload;
    },
    deleteJobSeekerLocationFrom: (state) => {
      state.from = null;
    },
    rememberJobSeekerApplyJobId: (state, action: PayloadAction<string>) => {
      state.applyJobId = action.payload;
    },
    deleteJobSeekerApplyJobId: (state) => {
      state.applyJobId = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(renewAccessToken.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(renewAccessToken.fulfilled, (state) => {
      state.status = 'fulfilled';
      state.error = null;
    });
    builder.addCase(renewAccessToken.rejected, (state, action) => {
      state.status = 'rejected';
      if (action.payload) {
        state.error = action.payload;
      }
    });

    builder.addCase(getCurrentJobSeeker.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(getCurrentJobSeeker.fulfilled, (state, action: PayloadAction<JobSeeker>) => {
      state.isAuthenticated = action.payload ? true : false;
      state.jobSeeker = action.payload;
      state.status = 'fulfilled';
      state.error = null;
    });
    builder.addCase(getCurrentJobSeeker.rejected, (state, action) => {
      state.isAuthenticated = false;
      state.jobSeeker = null;
      state.status = 'rejected';

      if (action.payload) {
        state.error = action.payload;
      }
    });
  },
});

export const { jobSeekerSignOut } = jobSeekerAuthSlice.actions;

export const deleteSession = (reason: string = 'Signed out'): AppThunk => {
  return (dispatch) => {
    tokenStorage.clear();
    dispatch(jobSeekerSignOut(reason));
  };
};

export const selectIsJobSeekerAuthenticated = (state: RootState): boolean => state.jobSeekerAuth.isAuthenticated && !!state.jobSeekerAuth.jobSeeker;
export const selectJobSeeker = (state: RootState): JobSeeker | null => state.jobSeekerAuth.jobSeeker;
export const { rememberJobSeekerLocation, deleteJobSeekerLocationFrom, rememberJobSeekerApplyJobId, deleteJobSeekerApplyJobId } = jobSeekerAuthSlice.actions;

export const jobSeekerAuthReducer = jobSeekerAuthSlice.reducer;
