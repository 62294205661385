import { AnyAction, combineReducers, Reducer } from '@reduxjs/toolkit';
import { authReducer, signOut } from 'features/auth';
import { registerReducer } from 'features/register';
import { organizationSetupReducer, organizationReducer, employerDetailsReducer } from 'features/organizations';
import { employerSetupReducer } from 'features/employers';
import { programReducer } from 'features/programs';
import { jobSeekerReducer } from 'features/job-seekers';
import { userReducer } from 'features/users';
import { jobSeekerInvitationReducer } from 'features/job-seekers-invitation';
import { jobsReducer } from 'features/jobs';
import { opportunitiesReducer } from 'features/opportunities';
import { exploreProgramsReducer } from 'features/explore-programs';
import { discoverEmployersReducer } from 'features/discover-employers';
import { jobSeekerSignInWithCodeReducer } from 'features/job-seeker-sign-in-with-code';
import { jobSeekerAuthReducer } from 'features/job-seeker-auth';
import { jobSeekerProfileReducer } from 'features/job-seeker-profile/job-seeker-profile';
import { jobSeekerOpportunitiesReducer } from 'features/job-seeker-opportunities';
import { jobSeekerExploreProgramsReducer } from 'features/job-seeker-explore-programs';
import { networkJoinReducer } from 'features/network-join';
import { networkSubscribeReducer } from 'features/network-subscribe';
import { opportunitiesFiltersReducer } from 'features/opportunities-filters';
import { jobSeekerRespondReducer } from 'features/job-seeker-respond';
import { provideTheDetailsReducer } from 'features/provide-the-details';
import { notificationSettingsReducer } from 'features/notification-settings';
import { jobBoardReducer } from 'features/job-board-settings';
import { resourcesReducer } from 'features/resources';
import { jobSeekerResourcesReducer } from 'features/job-seeker-resources';
import { employerCreateReducer } from 'features/employer-create';
import { organizationCreateReducer } from 'features/organization-create';
import { discoverProgramsReducer } from 'features/discover-programs';
import { discoverNetworksReducer } from 'features/discover-networks';
import { discoverResourcesReducer } from 'features/discover-resources';
import { resourceDetailsReducer } from 'features/resource-details';
import { userProfileReducer } from 'features/user-profile';
import { jobFormReducer } from 'features/job-form';
import { organizationJobsReducer } from 'features/organization-jobs';
import { jobSeekerManageReducer } from 'features/job-seeker-manage';
import { programsFiltersReducer } from 'features/programs-filters';
import { jobSeekerEmploymentStatusReducer } from 'features/job-seeker-employment-status';
import { jobSeekerInitialDataReducer } from 'features/job-seeker-initial-entry';
import { jobResponsesByOrganizationReducer } from 'features/job-responses-by-organization';
import { jobResponsesByEmployerReducer } from 'features/job-responses-by-employer';
import { jobResponsesByJobSeekerReducer } from 'features/job-responses-by-job-seeker';
import { discoverResourcesByOrganizationReducer } from 'features/discover-resources-by-organization';
import { resourceShareReducer } from 'features/resource-share';
import { jobSeekerManageForManagerReducer } from 'features/job-seeker-manage-for-manager';

const appReducer = combineReducers({
  auth: authReducer,
  register: registerReducer,
  organizationSetup: organizationSetupReducer,
  employerSetup: employerSetupReducer,
  program: programReducer,
  jobSeeker: jobSeekerReducer,
  organization: organizationReducer,
  user: userReducer,
  jobSeekerInvitation: jobSeekerInvitationReducer,
  employerDetails: employerDetailsReducer,
  jobs: jobsReducer,
  opportunities: opportunitiesReducer,
  explorePrograms: exploreProgramsReducer,
  discoverEmployers: discoverEmployersReducer,
  jobSeekerSignInWithCode: jobSeekerSignInWithCodeReducer,
  jobSeekerAuth: jobSeekerAuthReducer,
  jobSeekerProfile: jobSeekerProfileReducer,
  jobSeekerOpportunities: jobSeekerOpportunitiesReducer,
  jobSeekerExplorePrograms: jobSeekerExploreProgramsReducer,
  networkJoin: networkJoinReducer,
  networkSubscribe: networkSubscribeReducer,
  opportunitiesFilters: opportunitiesFiltersReducer,
  jobSeekerRespond: jobSeekerRespondReducer,
  provideTheDetails: provideTheDetailsReducer,
  notificationSettings: notificationSettingsReducer,
  jobBoardSettings: jobBoardReducer,
  resources: resourcesReducer,
  resourceDetails: resourceDetailsReducer,
  jobSeekerResources: jobSeekerResourcesReducer,
  employerCreate: employerCreateReducer,
  organizationCreate: organizationCreateReducer,
  discoverPrograms: discoverProgramsReducer,
  discoverNetworks: discoverNetworksReducer,
  discoverResources: discoverResourcesReducer,
  userProfile: userProfileReducer,
  jobForm: jobFormReducer,
  organizationJobs: organizationJobsReducer,
  jobSeekerManage: jobSeekerManageReducer,
  jobSeekerManageForManager: jobSeekerManageForManagerReducer,
  programsFilters: programsFiltersReducer,
  jobSeekerEmploymentStatus: jobSeekerEmploymentStatusReducer,
  jobSeekerInitialData: jobSeekerInitialDataReducer,
  jobResponsesByOrganization: jobResponsesByOrganizationReducer,
  jobResponsesByEmployer: jobResponsesByEmployerReducer,
  jobResponsesByJobSeeker: jobResponsesByJobSeekerReducer,
  discoverResourcesByOrganization: discoverResourcesByOrganizationReducer,
  resourceShare: resourceShareReducer,
});

export type RootState = ReturnType<typeof appReducer>;

const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
  if (action.type === signOut.type) {
    state = {} as RootState;
    return appReducer(state, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
